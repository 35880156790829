// @ts-nocheck
/* eslint-disable */

import {useEffect, useState} from 'react';
import {usePathname} from 'next/navigation';
import {SideBarRoutes} from '@/_core/routes';
import {getIsActiveRoute} from '@/_core/util/routesHelper';
import {flowRoute} from '@/_core/util/util';
import {useFeatureFlags} from '@modules/Core/hooks/featureFlags';
import {_NewSideBarRouteItems, _NewSideBarRoutes} from '@modules/Core/types/routesTypes';
import {logger} from '@modules/Core/util/Logger';
import {copyObject} from '@modules/Core/util/util';
import {_FlowSchema} from '@modules/FlowEngine/types/core.model';
import {_Institution} from '@modules/Institution/types/institution.model';
import {_Profile} from '@modules/Profile/types/profile.model';
import {_Team} from '@modules/Team/types/team.model';

/**
 * We want to fetch all routes
 * A route has a section (profile, teams, organisation, softfactionary)
 * A section has a list of items
 * An item has a to, value, displayValue, icon, shouldDisplay, data
 */

export const usePrepareSideBar = (
  availableRoutes: Record<string, _FlowSchema> = {},
  currentProfile: _Profile | null,
  currentInstitution?: _Institution | null,
  teams: _Team[] = []
): _NewSideBarRoutes => {
  const [items, setItems] = useState<_NewSideBarRoutes>(() => prepareItems());
  const pathname = usePathname();
  const {isFeatureEnabled} = useFeatureFlags();

  useEffect(() => {
    const profileItems = prepareItems(
      pathname,
      currentProfile,
      currentInstitution,
      availableRoutes,
      SideBarRoutes.profile.items,
      null,
      {},
      isFeatureEnabled
    );
    const teamEntries = prepareTeamSideBarItems(
      pathname,
      currentProfile,
      currentInstitution,
      availableRoutes,
      SideBarRoutes.organisation.items.filter(item => item.type === 'team'),
      teams,
      isFeatureEnabled
    );
    const institutionEntries = prepareItems(
      pathname,
      currentProfile,
      currentInstitution,
      availableRoutes,
      SideBarRoutes.organisation.items.filter(item => item.type !== 'team'),
      null,
      {
        institutionId: currentInstitution?.id,
      },
      isFeatureEnabled
    );
    const generalEntries = prepareItems(
      pathname,
      currentProfile,
      currentInstitution,
      availableRoutes,
      SideBarRoutes.general.items,
      null,
      {},
      isFeatureEnabled
    );

    // Concat items of institution and team
    setItems({
      profile: {
        items: profileItems,
      },
      organisation: {
        items: [...teamEntries, ...institutionEntries],
      },
      general: {
        items: generalEntries,
      },
    });
  }, [availableRoutes, currentProfile, currentInstitution, teams]);

  return items;
};

const prepareTeamSideBarItems = (
  pathname: string,
  currentProfile?: _Profile,
  currentInstitution?: _Institution,
  availableRoutes: Record<string, _FlowSchema> = {},
  items: _NewSideBarRouteItems = [],
  teams: any[] = [],
  isFeatureEnabled?: (featureFlag: string) => boolean
): _NewSideBarRouteItems => {
  // iterate sections
  if (items) {
    const preparedItems: _NewSideBarRouteItems = [];
    const extractedItems: _NewSideBarRouteItems = items.filter(item => item.type === 'team').map(item => item);
    // For each team we create N + 1 entries.
    // N -> N-items in the team
    // 1 -> Team name collapsable item
    for (const team of teams) {
      for (const extractedItem of extractedItems) {
        // Add collapsable team name
        const teamItem = copyObject(extractedItem.default);
        teamItem.displayValue = extractedItem.displayValue({team});
        teamItem.collapsable = true;
        teamItem.to = flowRoute(extractedItem.default.flowKey, team.id);
        if (!teamItem.pattern) {
          teamItem.pattern = flowRoute(extractedItem.default.flowKey, team.id);
        }
        teamItem.value = `team_${team.id}`;
        // add team items
        teamItem.items = prepareItems(
          pathname,
          currentProfile,
          currentInstitution,
          availableRoutes,
          extractedItem.items,
          team.id,
          {},
          isFeatureEnabled
        );
        // active if any of the items is active
        teamItem.active = teamItem.items.some(item => item.active);
        preparedItems.push(teamItem);
      }
    }

    return preparedItems;
  }

  return [];
};

const prepareItems = (
  pathname: string,
  currentProfile?: _Profile | null,
  currentInstitution?: _Institution,
  availableRoutes: Record<string, _FlowSchema>,
  items: _NewSideBarRouteItems,
  teamId?: string,
  params: Record<string, any> = {},
  isFeatureEnabled?: (featureFlag: string) => boolean
): _NewSideBarRouteItems => {
  logger.debug('[rolestest]Processing items', {items, params});
  return (
    items
      ?.filter(item => !item.flowKey || availableRoutes?.[item.flowKey])
      ?.filter(item => !item.shouldDisplay || item.shouldDisplay?.({currentProfile, currentInstitution, teamId}))
      ?.filter(item => !item.featureFlag || isFeatureEnabled?.(item.featureFlag))
      ?.map(itemOriginal => {
        const item = copyObject(itemOriginal);
        if (!item) {
          return item;
        }
        // for each items, enrich shouldDisplay, cause callback and copyObject is JSON.stringify
        item.shouldDisplay = itemOriginal.shouldDisplay;

        if (item.collapsable) {
          item.items = prepareItems(
            pathname,
            currentProfile,
            currentInstitution,
            availableRoutes,
            itemOriginal.items,
            teamId,
            params
          );
          // if display value is a function, we call it with the teamId
          if (itemOriginal.displayValue && typeof itemOriginal.displayValue === 'function') {
            item.displayValue =
              itemOriginal.displayValue({
                teamId,
                currentProfile,
                currentInstitution,
              }) ?? itemOriginalep.displayValue;
          }

          // set default
          item.to = flowRoute(item.default.flowKey, teamId, params);

          if (!item.pattern) {
            item.pattern = flowRoute(item.default.flowKey, teamId);
          }

          item.active = getIsActiveRoute(pathname, item.pattern);
          return item;
        }

        if (item.flowKey) {
          item.to = flowRoute(item.flowKey, teamId, params);
          if (!item.pattern) {
            item.pattern = flowRoute(item.flowKey, teamId);
          }
        }

        if (teamId) {
          item.value = `${item.value}_${teamId}`;
        }
        item.active = getIsActiveRoute(pathname, item.pattern);
        return item;
      }) ?? []
  );
};
