// @ts-nocheck
/* eslint-disable */

import React from 'react';

export const BackgroundIcons: React.FC = () => (
  <div
    id="backgroundIcons"
    style={{
      position: 'absolute',
      pointerEvents: 'none',
      userSelect: 'none',
      width: 'var(--global-width)',
      zIndex: -1,
    }}
  >
    <img
      src="/assets/img/backgrounds/cross.svg"
      style={{
        width: 32,
        height: 33,
        position: 'absolute',
        top: -55,
        left: -40,
      }}
      alt="soft.fact"
    />

    <img
      src="/assets/img/backgrounds/contour.svg"
      style={{
        width: 100,
        height: 138,
        position: 'absolute',
        top: 50,
        right: -50,
      }}
      alt="soft.fact"
    />

    <img
      src="/assets/img/backgrounds/arrow.svg"
      style={{
        width: 80,
        position: 'absolute',
        top: 340,
        left: -90,
      }}
      alt="soft.fact"
    />
  </div>
);
