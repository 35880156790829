// @ts-nocheck
/* eslint-disable */

import React, {ReactElement, useEffect, useMemo, useState} from 'react';
import {Footer} from '@/_core/footer/Footer';
import {PageLayoutHeader} from '@/_core/layout/PageLayoutHeader';
import './PageLayout.scss';
import {ContainerlessLayout} from '@/_core/layouts/ContainerlessLayout';
import {DefaultLayout} from '@/_core/layouts/DefaultLayout';
import {PageLayoutDevFooter} from '@/_core/PageLayoutDevFooter';
import {usePageLayout} from '@modules/Core/hooks/state/pageLayout';
import {_LayoutType} from '@modules/Core/types/pageLayout.model';
import {logger} from '@modules/Core/util/Logger';
import {BackgroundIcons} from '@modules/Core/util/ui/BackgroundIcons';

interface _PageLayout {
  children: ReactElement;
  setShowSidebar?: (show: boolean) => void;
}

// Adds a layout to every page.
// The content of every page is rendered in a card with a width of 10-columns.
// Also introduces the group as the upmost div-container for the page defining the "pageGap" of 8 (64px) between every section of the group.

/**
 * Main App Layout
 * Renders Header with title and actions
 * Renders loader or main content
 * @param setShowSidebar
 * @param children
 * @constructor
 */
export const PageLayout: React.FC<_PageLayout> = ({setShowSidebar, children}) => {
  const {options, title, actions, subTitle, softwareReleaseKey} = usePageLayout();
  const [currentView, setCurrentView] = useState<_LayoutType>('default');
  const header = (
    <PageLayoutHeader
      options={options}
      title={title}
      subTitle={subTitle}
      softwareReleaseKey={softwareReleaseKey}
      actions={actions}
      setShowSidebar={setShowSidebar}
    />
  );
  const footer = <Footer />;
  const devFooter = <PageLayoutDevFooter />;
  const backgroundIcons = <BackgroundIcons />;

  useEffect(() => {
    if (!!options?.layout && options.layout !== currentView) {
      setCurrentView(options.layout);
    }
  }, [options]);

  // Only component type should be memoized, otherwise we rerender children -> which rerenders flow -> rerenders layoutOptions
  // -> infinite loop
  const LayoutComponent = useMemo(() => {
    if (currentView === 'no-container') {
      return ContainerlessLayout;
    }
    return DefaultLayout;
  }, [currentView]);

  logger.debug('[PageLayout] title', {actions});

  return (
    <LayoutComponent backgroundIcons={backgroundIcons} devFooter={devFooter} footer={footer} header={header}>
      {children}
    </LayoutComponent>
  );
};
