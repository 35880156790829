// @ts-nocheck
/* eslint-disable */

import React, {useEffect} from 'react';
import {ContentEngineContainer} from '@modules/ContentEngine/components/base/ContentEngineContainer';
import {addReactEventListener, reloadContentEngine, removeReactEventListener} from '@modules/Core/util/eventsUtil';
import {logger} from '@modules/Core/util/Logger';
import {isDevMode} from '@modules/Core/util/util';
import {LOCATION_CHANGED_APP_EVENT, PROFILE_RELOADED_APP_EVENT} from '@modules/Navigation/hooks/navigationInit';

const key = 'sidebarPrompts';
export const AppSidebarFooter: React.FC = () => {
  const showDevMenu = isDevMode();
  useEffect(() => {
    const onRouteChange = (): void => {
      // will reload the sidebar prompts
      setTimeout(() => {
        logger.info('[AppSidebarFooter] Reloading sidebar prompts');
        reloadContentEngine(key);
      }, 1000);
    };

    addReactEventListener(PROFILE_RELOADED_APP_EVENT, onRouteChange);
    addReactEventListener(LOCATION_CHANGED_APP_EVENT, onRouteChange);

    return () => {
      removeReactEventListener(LOCATION_CHANGED_APP_EVENT, onRouteChange);
      removeReactEventListener(PROFILE_RELOADED_APP_EVENT, onRouteChange);
    };
  }, []);

  logger.debug('[AppSidebarFooter] Rendering', {showDevMenu});
  return (
    <>
      {showDevMenu && <ContentEngineContainer reloadKey={key} type="sidebarPromptsDev" withLoadingIndicator={false} />}
      <ContentEngineContainer reloadKey={key} type="sidebarPrompts" withLoadingIndicator={false} />
    </>
  );
};
