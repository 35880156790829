// @ts-nocheck
/* eslint-disable */

import React from 'react';
import {SideBarEntries} from '@/_core/sideBar/SideBarEntries';
import {AvatarUser} from '@modules/Core/components/base/avatar/AvatarUser';
import {IconOrganisation} from '@modules/Core/components/base/Icons/Icons';
import {_NewSideBarRoute} from '@modules/Core/types/routesTypes';
import {useInstitution} from '@modules/Institution/hooks/Institution';
import {useProfile} from '@modules/Profile/hooks/profile';

interface _SideBarOrganization {
  data: _NewSideBarRoute;
}

export const SideBarOrganization: React.FC<_SideBarOrganization> = ({data}) => {
  const [userProfile] = useProfile();
  const {currentInstitution} = useInstitution();

  if (!userProfile || !currentInstitution) {
    console.error('No user found');
    return null;
  }

  return (
    <div className="flex items-start gap-1 flex-col w-full">
      <div className="flex pl-2 w-full h-5 items-center justify-start">
        <AvatarUser label={currentInstitution.name} icon={IconOrganisation} lineClamp />
      </div>

      <SideBarEntries data={data} />
    </div>
  );
};
